<template>
  <v-app>
    <v-container>
      <v-subheader class="mb-0 py-0" height="20">
        <h2>{{ student.empname }}成绩册</h2>
      </v-subheader>
      <v-card>
        <v-card-title> 系统提示 </v-card-title>
        <v-card-text style="font-size:14pt">
          由于区成绩数据格数标准与我校 <span class="error--text">新课标素养评价数据标准</span> 不兼容，暂缓成绩数据对接服务。
          请一、二、三年级访问学校雏鹰素养评价平台，四、五、六访问学校社区成绩评价平台。
        </v-card-text>
      </v-card>
      <!--
      <v-card
        v-for="(ds, k) in data.mark"
        :key="k"
        class="mb-3"
        elevation="1"
        tile
        @click="
          $router.push({
            path: 'student-mark',
            query: {
              student: JSON.stringify(student),
              year: k,
            },
          })
        "
        v-show="k <= data.term.end"
      >
        <v-card-title> {{ k }}-{{ parseInt(k) + 1 }}学年度成绩表 </v-card-title>
      </v-card>
      -->
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      /*
      student: {
        loginid: "chenmingge_103s",
        empname: "陈茗格",
        schoolcode: "103",
        mobile: "13798287515",
        sfz: "440303201108044821",
        sex: true,
        identity: 0,
      },
      */
      student: {
        loginid: "",
        empname: "",
        schoolcode: "",
        mobile: "",
        sfz: "",
        sex: true,
        identity: 0,
      },
      data: [],
    };
  },
  created() {},
  mounted() {
    //this.luohu();
  },
  methods: {
    getUrlParams(url) {
      const Params = {};
      if (url.indexOf("?") > 0) {
        //判断是否有qurey
        let parmas = url.slice(url.indexOf("?") + 1); //截取出query
        const paramlists = parmas.split("&"); //分割键值对
        for (const param of paramlists) {
          let a = param.split("=");
          Object.assign(Params, { [a[0]]: a[1] }); //将键值对封装成对象
        }
      }
      return Params;
    },
    luohu() {
      let that = this;
      if (this.$route.query.student) {
        this.student = JSON.parse(this.$route.query.student);

        setTimeout(() => {
          that.load();
        }, 100);
      } else {
        let query = this.getUrlParams(window.location.href);
        this.$sopen
          .requestLuohu("https://web.luohuedu.net/tx/userinfo.aspx?code=" + query.code)
          .then((res) => {
            this.student = res.data;
            this.$router.push({
              query: {
                student: JSON.stringify(this.student),
              },
            });
            setTimeout(() => {
              that.load();
            }, 100);
          });
      }
    },
    load() {
      let data = {
        module: "student_mark",
        title: "get_user",
        data: {
          name: this.student.empname,
          idcard: this.student.sfz,
          schoolid: "440201000103",
        },
      };
      console.log(data);
      //let that = this;
      this.$sopen.requestApi(data).then((res) => {
        console.log(res);
        this.data = res.student_mark;
      });
    },
  },
};
</script>

<style></style>
